import React from "react";

function HowTo() {
    return (
        <div className="container text-center">
            <h1>How to use Describer</h1>
            <div className="row">
                <div className="col-lg-12 mt-5">
                    To get the Describer Chrome Extension: &nbsp; &nbsp;
                    <a className="btn btn-outline-dark start-two" href="https://chrome.google.com/webstore/detail/describer/beofflbjdakfgeglikjadebcgnjjkpdh" target="_blank" rel="noreferrer">Click Here</a>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 mt-5">
                    <h4>Creating a PR Description</h4>
                    <div className="col-sm-9" style={{ margin: "auto", marginTop: "15px", marginBottom: "10px" }}>
                        Start by drafting a comment the way you normally would for inline comments on Github. Click the "Describe It" button to save.
                    </div>
                    <div className="col-sm-9 tutorialCreate1"></div>
                    <div className="col-sm-9" style={{ margin: "auto", marginTop: "15px", marginBottom: "10px" }}>
                        Once you have commented on all the lines of code you want, click the Describer icon at the top of the browser. Click the "Copy" button on the popup window.
                        This will copy the contents of the Description to your clipboard. Paste on a new line in the PR description.
                    </div>
                    <div className="col-sm-9 tutorialCreate2" style={{ marginBottom: "150px" }}></div>
                </div>
                <div className="col-lg-6 mt-5">
                    <h4>Reviewing a PR</h4>
                    <div className="col-sm-9" style={{ margin: "auto", marginTop: "15px", marginBottom: "10px" }}>
                        To review a PR with describer, go to the PR and click the "Run Describer" button. If the button is not showing up, try refreshing the page or double check that the
                        PR Description actually has a describer link in it.
                    </div>
                    <div className="col-sm-9 tutorialRead1" style={{ marginBottom: "150px" }}></div>
                </div>
            </div>
        </div >
    );
};

export default HowTo;