import React, { Component } from "react";
// import logo from '../../components/Logo/logo.svg';

import Header from '../../components/Header/Header';
import Content from './Content';
import Footer from '../../components/Footer/Footer';
import Privacy from "./Privacy";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import HowTo from "./HowTo";

const router = createBrowserRouter([
    {
        path: "/privacy",
        element: <Privacy />,
    },
    {
        path: "/howto",
        element: <HowTo />,
    },
    {
        path: "/",
        element: <Content />,
    }
]);

class Index extends Component {
    render() {
        return (
            <div class="main-container">
                <Header />
                <RouterProvider router={router} />
                <Footer />
            </div>

        )
    }
}

export default Index;