import React from "react"

function Footer() {
    return (
        <footer class="page-footer font-small blue footer">
            <div class="footer-copyright text-center py-3">
                Built by <span className="author"> Ethan Bond</span> 🚀
            </div>
        </footer>
    );
}

export default Footer;