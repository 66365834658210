import React from "react";
var __html = require('./Privacy.html.js');
var template = { __html: __html };

function Privacy() {
    return (
        <span dangerouslySetInnerHTML={template} />
    );
};

export default Privacy;