import React from 'react';
// import logo from './logo.svg';
import './App.css';

import Index from './views/Index/Index';

function App() {
  return (
    <Index />
  );
}

export default App;
