import React from "react"

function Nav() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light static-top header-a">
            <div className="container nav-container">
                <img src="/logo192.png" height={32} width={32} style={{ marginRight: '15px' }} alt="Describer Logo"></img>
                <a className="navbar-brand brand" href="https://www.describer.io">Describer</a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse alink" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Courses
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="#">Action</a>
                                <a className="dropdown-item" href="#">Another action</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#">Something else here</a>
                            </div>
                        </li> */}

                        <li className="nav-item active">
                            <a className="nav-link" href="/howto">How to use <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/privacy">Privacy</a>
                        </li>

                        <a className="btn btn-outline-dark start" href="https://chrome.google.com/webstore/detail/describer/beofflbjdakfgeglikjadebcgnjjkpdh" target="_blank" rel="noreferrer">Add to chrome</a>
                    </ul>


                </div>


            </div>
        </nav>
    );
}

export default Nav;